import "dayjs/locale/pt";
import moment from "moment";
import "moment/locale/pt";

import { dayjs } from "utils/date";
import i18n from "utils/i18n";

import { DEFAULT_LOCALE } from "../constants";
import { getLocaleCookie, setLocaleCookie } from "./cookies";

// context(alexandrchebotar, 2024-12-16): set default locale as loading resources switches it automatically
dayjs.locale(DEFAULT_LOCALE);
moment.locale(DEFAULT_LOCALE);

export const applyLocale = (locale: string, reload?: boolean) => {
  i18n.changeLanguage(locale);
  dayjs.locale(locale);
  moment.locale(locale);
  setLocaleCookie(locale);

  if (reload) {
    // context(alexandrchebotar, 2024-12-06): need to reload page to be sure that Liveview content also changed the locale
    window.location.reload();
  }
};

export const initLocale = () => {
  // context(alexandrchebotar, 2024-12-10): use DEFAULT_LOCALE without setting locale to cookies when the feature flag is disabled

  const { pathname } = window.location;
  const isNumberPage = pathname.includes("number/");
  const isOnboardingPage = pathname.includes("onboarding/");

  const currentLocale = getLocaleCookie();

  if (currentLocale) {
    // context(alexandrchebotar, 2024-12-06): renew cookie value  to prevent 400-days limit
    applyLocale(currentLocale);
    return currentLocale;
    // context(alexandrchebotar, 2024-12-11): skip autodetecting outside of Onboarding flow or Number page
  } else if (isNumberPage || isOnboardingPage) {
    // context(alexandrchebotar, 2024-12-09): use default locale if there is no supported navigator language
    applyLocale(DEFAULT_LOCALE);
    return DEFAULT_LOCALE;
  }

  return DEFAULT_LOCALE;
};
