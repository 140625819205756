import langs from "langs";
import _isObject from "lodash/isObject";
import _isString from "lodash/isString";
import _uniqBy from "lodash/uniqBy";

import { isDefined } from "utils/checks";

const extraAutomationLanguages = [
  { name: "Aasáx", 3: "aas" },
  { name: "Mesopotamian Arabic", 3: "acm" },
  { name: "Adangme", 3: "ada" },
  { name: "Aari", 3: "aiw" },
  { name: "A'ou", 3: "aou" },
  { name: "Bemba (Zambia)", 3: "bem" },
  { name: "Abadi", 3: "kbt" },
  { name: "Kimbundu", 3: "kmb" },
  { name: "Malawi Lomwe", 3: "lon" },
  { name: "Dholuo / Luo (Kenya and Tanzania)", 3: "luo" },
  { name: "Makhuwa-Meetto", 3: "mgh" },
  { name: "Northern Sotho / Pedi/ Sepedi", 3: "nso" },
  { name: "Ronga", 3: "rng" },
  { name: "Kiswahili", 3: "swh" },
  { name: "Tetun Dili", 3: "tdt" },
  { name: "Umbundu", 3: "umb" },
  { name: "Soga", 3: "xog" },
];

type TSortEnglishFirst = {
  (): (a: string, b: string) => -1 | 0 | 1;
  <T extends string | number>(key: T): (a: Record<T, string>, b: Record<T, string>) => -1 | 0 | 1;
};
export const sortEnglishFirst: TSortEnglishFirst = (key?: string | number) => (a: any, b: any) => {
  const isEnglish = (item: any) => {
    const language = key && _isObject(item) ? item[key] : item;

    return _isString(language) && ["en", "eng"].includes(language.toLowerCase());
  };

  return isEnglish(a) ? -1 : isEnglish(b) ? 1 : 0;
};

export type WhatsAppLanguageType = {
  iso: string;
  wa: string;
  name: string;
};

export const whatsAppLanguageMap: WhatsAppLanguageType[] = [
  { iso: "afr", wa: "AF", name: "Afrikaans" },
  { iso: "sqi", wa: "SQ", name: "Albanian" },
  { iso: "ara", wa: "AR", name: "Arabic" },
  { iso: "arz_EG", wa: "ar_EG", name: "Arabic (EGY)" },
  { iso: "ara_AE", wa: "ar_AE", name: "Arabic (UAE)" },
  { iso: "ara_LB", wa: "ar_LB", name: "Arabic (LBN)" },
  { iso: "ary_MA", wa: "ar_MA", name: "Arabic (MAR)" },
  { iso: "ara_QA", wa: "ar_QA", name: "Arabic (QAT)" },
  { iso: "aze", wa: "AZ", name: "Azerbaijani" },
  { iso: "bel_BY", wa: "be_BY", name: "Belarusian" },
  { iso: "ben", wa: "BN", name: "Bengali" },
  { iso: "ben_IN", wa: "bn_IN", name: "Bengali (IND)" },
  { iso: "bul", wa: "BG", name: "Bulgarian" },
  { iso: "cat", wa: "CA", name: "Catalan" },
  { iso: "zho_CN", wa: "ZH_CN", name: "Chinese (CHN)" },
  { iso: "yue_HK", wa: "ZH_HK", name: "Chinese (HKG)" },
  { iso: "zho_TW", wa: "ZH_TW", name: "Chinese (TAI)" },
  { iso: "hrv", wa: "HR", name: "Croatian" },
  { iso: "ces", wa: "CS", name: "Czech" },
  { iso: "dan", wa: "DA", name: "Danish" },
  { iso: "prs", wa: "prs_AF", name: "Dari" },
  { iso: "nld", wa: "NL", name: "Dutch" },
  { iso: "nld_BE", wa: "nl_BE", name: "Dutch (BEL)" },
  { iso: "eng", wa: "EN", name: "English" },
  { iso: "eng_GB", wa: "EN_GB", name: "English (UK)" },
  { iso: "eng_US", wa: "EN_US", name: "English (US)" },
  { iso: "eng_AE", wa: "en_AE", name: "English (UAE)" },
  { iso: "eng_AU", wa: "en_AU", name: "English (AUS)" },
  { iso: "eng_CA", wa: "en_CA", name: "English (CAN)" },
  { iso: "eng_GH", wa: "en_GH", name: "English (GHA)" },
  { iso: "eng_IE", wa: "en_IE", name: "English (IRL)" },
  { iso: "eng_IN", wa: "en_IN", name: "English (IND)" },
  { iso: "eng_JM", wa: "en_JM", name: "English (JAM)" },
  { iso: "eng_MY", wa: "en_MY", name: "English (MYS)" },
  { iso: "eng_NZ", wa: "en_NZ", name: "English (NZL)" },
  { iso: "eng_QA", wa: "en_QA", name: "English (QAT)" },
  { iso: "eng_SG", wa: "en_SG", name: "English (SGP)" },
  { iso: "eng_UG", wa: "en_UG", name: "English (UGA)" },
  { iso: "eng_ZA", wa: "en_ZA", name: "English (ZAF)" },
  { iso: "est", wa: "ET", name: "Estonian" },
  { iso: "fil", wa: "FIL", name: "Filipino" },
  { iso: "fin", wa: "FI", name: "Finnish" },
  { iso: "fra", wa: "FR", name: "French" },
  { iso: "fra_BE", wa: "fr_BE", name: "French (BEL)" },
  { iso: "fra_CA", wa: "fr_CA", name: "French (CAN)" },
  { iso: "fra_CH", wa: "fr_CH", name: "French (CHE)" },
  { iso: "fra_CI", wa: "fr_CI", name: "French (CIV)" },
  { iso: "fra_MA", wa: "fr_MA", name: "French (MAR)" },
  { iso: "kat", wa: "ka", name: "Georgian" },
  { iso: "deu", wa: "DE", name: "German" },
  { iso: "deu_AT", wa: "de_AT", name: "German (AUT)" },
  { iso: "deu_CH", wa: "de_CH", name: "German (CHE)" },
  { iso: "ell", wa: "EL", name: "Greek" },
  { iso: "guj", wa: "GU", name: "Gujarati" },
  { iso: "hau", wa: "HA", name: "Hausa" },
  { iso: "heb", wa: "HE", name: "Hebrew" },
  { iso: "hin", wa: "HI", name: "Hindi" },
  { iso: "hun", wa: "HU", name: "Hungarian" },
  { iso: "ind", wa: "ID", name: "Indonesian" },
  { iso: "gle", wa: "GA", name: "Irish" },
  { iso: "ita", wa: "IT", name: "Italian" },
  { iso: "jpn", wa: "JA", name: "Japanese" },
  { iso: "kan", wa: "KN", name: "Kannada" },
  { iso: "kaz", wa: "KK", name: "Kazakh" },
  { iso: "kin_RW", wa: "rw_RW", name: "Kinyarwanda" },
  { iso: "kor", wa: "KO", name: "Korean" },
  { iso: "kir_KG", wa: "ky_KG", name: "Kyrgyz (Kyrgyzstan)" },
  { iso: "lao", wa: "LO", name: "Lao" },
  { iso: "lav", wa: "LV", name: "Latvian" },
  { iso: "lit", wa: "LT", name: "Lithuanian" },
  { iso: "mkd", wa: "MK", name: "Macedonian" },
  { iso: "zlm", wa: "MS", name: "Malay" },
  { iso: "mal", wa: "ML", name: "Malayalam" },
  { iso: "mar", wa: "MR", name: "Marathi" },
  { iso: "nor", wa: "NB", name: "Norwegian" },
  { iso: "pus_AF", wa: "ps_AF", name: "Pashto" },
  { iso: "fas", wa: "FA", name: "Persian" },
  { iso: "pol", wa: "PL", name: "Polish" },
  { iso: "por_BR", wa: "PT_BR", name: "Portuguese (BR)" },
  { iso: "por_PT", wa: "PT_PT", name: "Portuguese (POR)" },
  { iso: "pan", wa: "PA", name: "Punjabi" },
  { iso: "ron", wa: "RO", name: "Romanian" },
  { iso: "rus", wa: "RU", name: "Russian" },
  { iso: "srp", wa: "SR", name: "Serbian" },
  { iso: "sin_LK", wa: "si_LK", name: "Sinhala" },
  { iso: "slk", wa: "SK", name: "Slovak" },
  { iso: "slv", wa: "SL", name: "Slovenian" },
  { iso: "spa", wa: "ES", name: "Spanish" },
  { iso: "spa_AR", wa: "ES_AR", name: "Spanish (ARG)" },
  { iso: "spa_ES", wa: "ES_ES", name: "Spanish (SPA)" },
  { iso: "spa_MX", wa: "ES_MX", name: "Spanish (MEX)" },
  { iso: "spa_CL", wa: "es_CL", name: "Spanish (CHL)" },
  { iso: "spa_CO", wa: "es_CO", name: "Spanish (COL)" },
  { iso: "spa_CR", wa: "es_CR", name: "Spanish (CRI)" },
  { iso: "spa_DO", wa: "es_DO", name: "Spanish (DOM)" },
  { iso: "spa_EC", wa: "es_EC", name: "Spanish (ECU)" },
  { iso: "spa_HN", wa: "es_HN", name: "Spanish (HND)" },
  { iso: "spa_PA", wa: "es_PA", name: "Spanish (PAN)" },
  { iso: "spa_PE", wa: "es_PE", name: "Spanish (PER)" },
  { iso: "spa_UY", wa: "es_UY", name: "Spanish (URY)" },
  { iso: "swh", wa: "SW", name: "Swahili" },
  { iso: "swe", wa: "SV", name: "Swedish" },
  { iso: "tam", wa: "TA", name: "Tamil" },
  { iso: "tel", wa: "TE", name: "Telugu" },
  { iso: "tha", wa: "TH", name: "Thai" },
  { iso: "tur", wa: "TR", name: "Turkish" },
  { iso: "ukr", wa: "UK", name: "Ukrainian" },
  { iso: "urd", wa: "UR", name: "Urdu" },
  { iso: "uzb", wa: "UZ", name: "Uzbek" },
  { iso: "vie", wa: "VI", name: "Vietnamese" },
  { iso: "zul", wa: "ZU", name: "Zulu" },
];

export const validTemplateLanguages = whatsAppLanguageMap.map(({ wa, name }) => ({
  code: wa.toUpperCase(),
  name,
}));

export const languages = _uniqBy(
  [...langs.all(), ...extraAutomationLanguages, ...whatsAppLanguageMap.map(({ name, iso }) => ({ 3: iso, name }))],
  "3"
)
  .map((lang) => ({
    ...lang,
    3: lang[3],
  }))
  .sort(({ name: a }, { name: b }) => a.localeCompare(b))
  .sort(sortEnglishFirst(3));

type TGetLanguageOptions = {
  (): Record<"label" | "value", string>[];
  <T extends string>(key: T): Record<T | "value", string>[];
};
export const getLanguageOptions: TGetLanguageOptions = (key = "label"): Record<string, string>[] =>
  languages.map((language) => ({
    [key]: language.name,
    value: language[3],
  }));
export const getContactLanguageOptions: TGetLanguageOptions = (key = "label"): Record<string, string>[] =>
  languages.map((language) => ({
    [key]: language.name,
    value: language[3],
  }));

const fixEnglishLanguageCode = (language: string) => (language === "en" ? "eng" : language);

export const getFilteredLanguages = (data: { language: string }[] = []) => {
  return [
    ...new Set([
      ...data
        .map((item) => fixEnglishLanguageCode(item.language))
        .sort(sortEnglishFirst())
        .map((language) => langs.where(3, language))
        .filter(isDefined),
    ]),
  ].map((item) => ({ ...item, value: item[3] }));
};

export const findLanguageByCode = (languageCode: string) => {
  const value = fixEnglishLanguageCode(languageCode);
  const label = languages.find((lang) => lang[3].toLowerCase() === value.toLowerCase())?.name ?? "Unknown";

  return { label, value };
};

export const findLanguageByLabel = (label: string) => {
  const value = languages.find((lang) => lang.name.toLowerCase() === label.toLowerCase())?.[3] ?? "";

  return { label, value };
};

export const getLanguageLabel = (langCode: string) => findLanguageByCode(langCode).label;
export const getLanguageCode = (langLabel: string) => findLanguageByLabel(langLabel).value;

export const getTemplatesLanguageLabel = (language?: string) =>
  language && validTemplateLanguages.find((l) => l.code === language.toUpperCase())?.name;

export const getTemplatesLanguagesOptions = (data?: { number: { messageTemplatesLanguages: string[] } } | null) =>
  data
    ? [...data.number.messageTemplatesLanguages]
        .sort((a, b) => a.localeCompare(b))
        .sort(sortEnglishFirst())
        .map((language) => validTemplateLanguages.find(({ code }) => language.toUpperCase() === code))
        .filter(isDefined)
        .map(({ code: value, name }) => ({ name, value }))
    : [];

export const getLanguageOptionsFromList = (languages: string[] = []) =>
  [...languages]
    .sort((a, b) => a.localeCompare(b))
    .sort(sortEnglishFirst())
    .map((language) => langs.where(3, language))
    .filter(isDefined)
    .map(({ 3: value, name }) => ({ name, value }));

export const getCardLanguageOptions = (data?: { number: { cardLanguages: string[] } } | null) =>
  getLanguageOptionsFromList(data?.number.cardLanguages);

export const getFormattedWhatsappLanguage = (language: string) => {
  const newLanguage = language
    .split("_")
    .map((item, index) => (index === 0 ? item.toLowerCase() : item.toUpperCase()))
    .join("_");

  return newLanguage ?? language.toLowerCase();
};
