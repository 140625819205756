/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";

/**
 * A custom effect hook that listens for events that fall outside the specified element's
 * DOM node tree.
 *
 * This is useful for closing modals, tooltips, popups, notifications, etc.
 *
 * IMPLEMENTATION NOTE:
 * This hook uses a global EventManager with flushSync to handle a specific issue with React Portals:
 *
 * 1. PORTAL PROBLEM:
 *    When components render content through portals, the DOM nodes exist outside their logical parent's
 *    DOM subtree. This means the standard node.contains(event.target) check fails for portal content.
 *
 * 2. EVENT TIMING ISSUE:
 *    With events (especially mousedown which is needed for React 18 compatibility), the event fires too early
 *    in the sequence for proper portal interaction. React's event system hasn't had time to process
 *    state updates from portal event handlers before our outside event logic executes.
 *
 * 3. SOLUTION:
 *    We use flushSync to force React to process all updates synchronously before determining
 *    if an event was "outside". This ensures correct behavior across portal boundaries.
 *
 * 4. PERFORMANCE OPTIMIZATION:
 *    The global EventManager consolidates all handlers to use a single flushSync call,
 *    minimizing the performance impact. We need this since flushing the tree will revert
 *    back to React 17 rendering behavior, which is not ideal.
 *
 *  Components rendering content in portals should ideally provide CSS classes or IDs to be
 *  added to the ignoreQuery option. Flushing the tree synchronously is a workaround to
 *  ensure that the click event is processed correctly, since we might not always be aware
 *  of external library components rendering content in portals.
 *
 * @param {function} fn A callback function which gets called when the event occurred outside the given DOM node
 * @param options Additional configuration options
 * @param {string[]} options.ignoreQuery CSS classes or IDs to ignore events from, like ["my-class", "modal-popup"]
 * @param {string[]} options.eventQuery CSS selectors to listen for specific events on, triggering eventQueryCallback
 * @param {boolean} options.useVisibility Whether to only respond to events when the visible flag is true
 * @param {boolean} options.visible Controls whether outside events should be active when useVisibility is true
 * @param {boolean} options.skip When true, temporarily disables the outside event detection
 * @param {string[]} options.events DOM event types to listen for, defaults to ["mousedown"]
 * @param {function} options.eventQueryCallback Function called when an event matches an eventQuery selector
 */

interface UseEventOutsideOptions {
  ignoreQuery?: string[]; // ["my-class-1", "should-ignore-outside-click"]
  eventQuery?: string[];
  useVisibility?: boolean;
  visible?: boolean;
  skip?: boolean;
  events?: string[];
  eventQueryCallback?: (value: string) => void;
}

// Update the composedPath utility to use the captured path
export const composedPath = (event: any, query: string, capturedPath?: Node[]) => {
  const path = capturedPath || event.path || (event.composedPath && event.composedPath()) || [];

  return path.length > 0
    ? path.some(
        ({ id, className }) =>
          (id && id === query) ||
          (className &&
            String(className)
              .split(" ")
              .some((cName) => cName === query))
      )
    : event.target.matches(`#${query}` || `.${query}`);
};

// Global event manager to consolidate flushSync calls
const EventManager = {
  handlers: new Map<number, { node: HTMLElement; callback: Function; events: string[] }>(),
  nextHandlerId: 0,
  registeredEvents: new Set<string>(),

  register(node: HTMLElement, callback: Function, events: string[]) {
    const id = this.nextHandlerId++;
    this.handlers.set(id, { node, callback, events });

    events.forEach((eventType) => {
      if (!this.registeredEvents.has(eventType)) {
        this.registerEventListener(eventType);
      }
    });

    return id;
  },

  unregister(id: number) {
    this.handlers.delete(id);
  },

  registerEventListener(eventType: string) {
    window.addEventListener(eventType, (e) => this.handleGlobalEvent(e, eventType), true);
    this.registeredEvents.add(eventType);
  },

  handleGlobalEvent(event: Event, eventType: string) {
    if (this.handlers.size > 0) {
      // Store a safe copy of the path immediately
      const eventPath = event.composedPath ? event.composedPath() : [];

      const toExecute: Function[] = [];

      this.handlers.forEach(({ node, callback, events }) => {
        if (events.includes(eventType)) {
          const isTargetChild = node?.contains(event.target as Node);
          if (!isTargetChild) {
            // Pass both the event and captured path to the callback
            toExecute.push(() => callback(event, eventPath));
          }
        }
      });

      if (toExecute.length > 0) {
        setTimeout(() =>
          flushSync(() => {
            toExecute.forEach((execute) => execute());
          })
        );
      }
    }
  },
};

export const useEventOutside = (fn = () => {}, options: UseEventOutsideOptions = { events: ["mousedown"] }) => {
  const [node, setRef] = useState<HTMLElement | null>();

  const memoizedOptions = useMemo(
    () => ({
      skip: options.skip,
      events: options.events || ["mousedown"],
      ignoreQuery: options.ignoreQuery,
      eventQuery: options.eventQuery,
      eventQueryCallback: options.eventQueryCallback,
      useVisibility: options.useVisibility,
      visible: options.visible,
    }),
    [
      options.skip,
      options.events,
      options.ignoreQuery,
      options.eventQuery,
      options.eventQueryCallback,
      options.useVisibility,
      options.visible,
    ]
  );

  useEffect(() => {
    const {
      skip,
      events = ["mousedown"],
      ignoreQuery,
      eventQuery,
      eventQueryCallback,
      useVisibility,
      visible,
    } = memoizedOptions;

    if (skip || !node) return;

    const wrappedCallback = (e: any, capturedPath?: Node[]) => {
      const shouldIgnore = () => {
        if (ignoreQuery) {
          return ignoreQuery.some((query) => composedPath(e, query, capturedPath));
        }
        return false;
      };

      const shouldCallAdditionalCallback = () => {
        if (eventQuery) {
          const matchedQuery = eventQuery.find((query) => composedPath(e, query, capturedPath));
          if (matchedQuery) {
            eventQueryCallback?.(matchedQuery);
            return true;
          }
        }
        return false;
      };

      if (useVisibility && !visible) return;
      if (!shouldIgnore() && !shouldCallAdditionalCallback()) fn();
    };

    const id = EventManager.register(node, wrappedCallback, events);

    return () => {
      EventManager.unregister(id);
    };
  }, [fn, memoizedOptions, node]);

  return [setRef];
};
