/* tslint:disable */
/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { ChatDetailsFragment } from "./fragments.generated";
import { ChatSearchResultsFragment } from "./fragments.generated";
import { ChatDetailsFragmentDoc } from "./fragments.generated";
import { ChatSearchResultsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type GetChatQueryVariables = Types.Exact<{
  chatUuid: Types.Scalars["ID"];
}>;

export type GetChatQuery = { __typename: "RootQueryType"; chat: ({ __typename: "Chat" } & ChatDetailsFragment) | null };

export type GetChatsQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
}>;

export type GetChatsQuery = {
  __typename: "RootQueryType";
  chats: Array<{
    __typename: "Chat";
    contact: {
      __typename: "Contact";
      failureCount: number | null;
      isFallbackActive: boolean | null;
      type: string | null;
      urn: string | null;
      uuid: string | null;
    } | null;
  } | null> | null;
};

export type GetCollectionChatsQueryVariables = Types.Exact<{
  collectionUuid: Types.Scalars["ID"];
  orderBy?: Types.InputMaybe<Types.Scalars["String"]>;
  state?: Types.InputMaybe<Types.Scalars["String"]>;
  beforeUuid?: Types.InputMaybe<Types.Scalars["ID"]>;
  afterUuid?: Types.InputMaybe<Types.Scalars["ID"]>;
}>;

export type GetCollectionChatsQuery = {
  __typename: "RootQueryType";
  collection: {
    __typename: "Collection";
    uuid: string | null;
    collectionCount: number | null;
    chats: Array<({ __typename: "Chat" } & ChatDetailsFragment) | null> | null;
  } | null;
};

export type ChatsAssignedToAccountQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  assignedToUuid: Types.Scalars["ID"];
  orderBy: Types.Scalars["String"];
  state?: Types.InputMaybe<Types.Scalars["String"]>;
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
}>;

export type ChatsAssignedToAccountQuery = {
  __typename: "RootQueryType";
  chatsAssignedToAccount: ({ __typename: "ChatSearchResults" } & ChatSearchResultsFragment) | null;
};

export type ChatsAssignedToAccountCountQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  assignedToUuid: Types.Scalars["ID"];
  orderBy: Types.Scalars["String"];
  state?: Types.InputMaybe<Types.Scalars["String"]>;
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
}>;

export type ChatsAssignedToAccountCountQuery = {
  __typename: "RootQueryType";
  chatsAssignedToAccount: { __typename: "ChatSearchResults"; count: number | null } | null;
};

export type AllChatsQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
  orderBy: Types.Scalars["String"];
  state?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type AllChatsQuery = {
  __typename: "RootQueryType";
  allChats: ({ __typename: "ChatSearchResults" } & ChatSearchResultsFragment) | null;
};

export type BlockedChatsQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
  orderBy: Types.Scalars["String"];
  state?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type BlockedChatsQuery = {
  __typename: "RootQueryType";
  blockedChats: ({ __typename: "ChatSearchResults" } & ChatSearchResultsFragment) | null;
};

export type UnassignedChatsQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
  orderBy: Types.Scalars["String"];
  state?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type UnassignedChatsQuery = {
  __typename: "RootQueryType";
  unassignedChats: ({ __typename: "ChatSearchResults" } & ChatSearchResultsFragment) | null;
};

export type WithBotChatsQueryVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
  orderBy: Types.Scalars["String"];
  state?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type WithBotChatsQuery = {
  __typename: "RootQueryType";
  withBot: ({ __typename: "ChatSearchResults" } & ChatSearchResultsFragment) | null;
};

export const GetChatDocument = gql`
  query getChat($chatUuid: ID!) {
    chat(chatUuid: $chatUuid) {
      ...ChatDetails
    }
  }
  ${ChatDetailsFragmentDoc}
`;

/**
 * __useGetChatQuery__
 *
 * To run a query within a React component, call `useGetChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatQuery({
 *   variables: {
 *      chatUuid: // value for 'chatUuid'
 *   },
 * });
 */
export function useGetChatQuery(baseOptions: Apollo.QueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, options);
}
export function useGetChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, options);
}
export type GetChatQueryHookResult = ReturnType<typeof useGetChatQuery>;
export type GetChatLazyQueryHookResult = ReturnType<typeof useGetChatLazyQuery>;
export type GetChatQueryResult = Apollo.QueryResult<GetChatQuery, GetChatQueryVariables>;
export const GetChatsDocument = gql`
  query getChats($numberUuid: ID!) {
    chats(numberUuid: $numberUuid) {
      contact {
        failureCount
        isFallbackActive
        type
        urn
        uuid
      }
    }
  }
`;

/**
 * __useGetChatsQuery__
 *
 * To run a query within a React component, call `useGetChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useGetChatsQuery(baseOptions: Apollo.QueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export function useGetChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export type GetChatsQueryHookResult = ReturnType<typeof useGetChatsQuery>;
export type GetChatsLazyQueryHookResult = ReturnType<typeof useGetChatsLazyQuery>;
export type GetChatsQueryResult = Apollo.QueryResult<GetChatsQuery, GetChatsQueryVariables>;
export const GetCollectionChatsDocument = gql`
  query getCollectionChats($collectionUuid: ID!, $orderBy: String, $state: String, $beforeUuid: ID, $afterUuid: ID) {
    collection(collectionUuid: $collectionUuid) {
      uuid
      collectionCount(state: $state)
      chats(orderBy: $orderBy, state: $state, beforeUuid: $beforeUuid, afterUuid: $afterUuid) {
        ...ChatDetails
      }
    }
  }
  ${ChatDetailsFragmentDoc}
`;

/**
 * __useGetCollectionChatsQuery__
 *
 * To run a query within a React component, call `useGetCollectionChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionChatsQuery({
 *   variables: {
 *      collectionUuid: // value for 'collectionUuid'
 *      orderBy: // value for 'orderBy'
 *      state: // value for 'state'
 *      beforeUuid: // value for 'beforeUuid'
 *      afterUuid: // value for 'afterUuid'
 *   },
 * });
 */
export function useGetCollectionChatsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCollectionChatsQuery, GetCollectionChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCollectionChatsQuery, GetCollectionChatsQueryVariables>(
    GetCollectionChatsDocument,
    options
  );
}
export function useGetCollectionChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionChatsQuery, GetCollectionChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCollectionChatsQuery, GetCollectionChatsQueryVariables>(
    GetCollectionChatsDocument,
    options
  );
}
export type GetCollectionChatsQueryHookResult = ReturnType<typeof useGetCollectionChatsQuery>;
export type GetCollectionChatsLazyQueryHookResult = ReturnType<typeof useGetCollectionChatsLazyQuery>;
export type GetCollectionChatsQueryResult = Apollo.QueryResult<
  GetCollectionChatsQuery,
  GetCollectionChatsQueryVariables
>;
export const ChatsAssignedToAccountDocument = gql`
  query chatsAssignedToAccount(
    $numberUuid: ID!
    $assignedToUuid: ID!
    $orderBy: String!
    $state: String
    $limit: Int
    $offset: Int
  ) {
    chatsAssignedToAccount(
      numberUuid: $numberUuid
      assignedToUuid: $assignedToUuid
      orderBy: $orderBy
      state: $state
      limit: $limit
      offset: $offset
    ) {
      ...ChatSearchResultsFragment
    }
  }
  ${ChatSearchResultsFragmentDoc}
`;

/**
 * __useChatsAssignedToAccountQuery__
 *
 * To run a query within a React component, call `useChatsAssignedToAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatsAssignedToAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatsAssignedToAccountQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      assignedToUuid: // value for 'assignedToUuid'
 *      orderBy: // value for 'orderBy'
 *      state: // value for 'state'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useChatsAssignedToAccountQuery(
  baseOptions: Apollo.QueryHookOptions<ChatsAssignedToAccountQuery, ChatsAssignedToAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatsAssignedToAccountQuery, ChatsAssignedToAccountQueryVariables>(
    ChatsAssignedToAccountDocument,
    options
  );
}
export function useChatsAssignedToAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatsAssignedToAccountQuery, ChatsAssignedToAccountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatsAssignedToAccountQuery, ChatsAssignedToAccountQueryVariables>(
    ChatsAssignedToAccountDocument,
    options
  );
}
export type ChatsAssignedToAccountQueryHookResult = ReturnType<typeof useChatsAssignedToAccountQuery>;
export type ChatsAssignedToAccountLazyQueryHookResult = ReturnType<typeof useChatsAssignedToAccountLazyQuery>;
export type ChatsAssignedToAccountQueryResult = Apollo.QueryResult<
  ChatsAssignedToAccountQuery,
  ChatsAssignedToAccountQueryVariables
>;
export const ChatsAssignedToAccountCountDocument = gql`
  query chatsAssignedToAccountCount(
    $numberUuid: ID!
    $assignedToUuid: ID!
    $orderBy: String!
    $state: String
    $limit: Int
    $offset: Int
  ) {
    chatsAssignedToAccount(
      numberUuid: $numberUuid
      assignedToUuid: $assignedToUuid
      orderBy: $orderBy
      state: $state
      limit: $limit
      offset: $offset
    ) {
      count
    }
  }
`;

/**
 * __useChatsAssignedToAccountCountQuery__
 *
 * To run a query within a React component, call `useChatsAssignedToAccountCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatsAssignedToAccountCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatsAssignedToAccountCountQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      assignedToUuid: // value for 'assignedToUuid'
 *      orderBy: // value for 'orderBy'
 *      state: // value for 'state'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useChatsAssignedToAccountCountQuery(
  baseOptions: Apollo.QueryHookOptions<ChatsAssignedToAccountCountQuery, ChatsAssignedToAccountCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatsAssignedToAccountCountQuery, ChatsAssignedToAccountCountQueryVariables>(
    ChatsAssignedToAccountCountDocument,
    options
  );
}
export function useChatsAssignedToAccountCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatsAssignedToAccountCountQuery, ChatsAssignedToAccountCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatsAssignedToAccountCountQuery, ChatsAssignedToAccountCountQueryVariables>(
    ChatsAssignedToAccountCountDocument,
    options
  );
}
export type ChatsAssignedToAccountCountQueryHookResult = ReturnType<typeof useChatsAssignedToAccountCountQuery>;
export type ChatsAssignedToAccountCountLazyQueryHookResult = ReturnType<typeof useChatsAssignedToAccountCountLazyQuery>;
export type ChatsAssignedToAccountCountQueryResult = Apollo.QueryResult<
  ChatsAssignedToAccountCountQuery,
  ChatsAssignedToAccountCountQueryVariables
>;
export const AllChatsDocument = gql`
  query allChats($numberUuid: ID!, $limit: Int, $offset: Int, $orderBy: String!, $state: String) {
    allChats(numberUuid: $numberUuid, orderBy: $orderBy, state: $state, limit: $limit, offset: $offset) {
      ...ChatSearchResultsFragment
    }
  }
  ${ChatSearchResultsFragmentDoc}
`;

/**
 * __useAllChatsQuery__
 *
 * To run a query within a React component, call `useAllChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllChatsQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useAllChatsQuery(baseOptions: Apollo.QueryHookOptions<AllChatsQuery, AllChatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllChatsQuery, AllChatsQueryVariables>(AllChatsDocument, options);
}
export function useAllChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllChatsQuery, AllChatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllChatsQuery, AllChatsQueryVariables>(AllChatsDocument, options);
}
export type AllChatsQueryHookResult = ReturnType<typeof useAllChatsQuery>;
export type AllChatsLazyQueryHookResult = ReturnType<typeof useAllChatsLazyQuery>;
export type AllChatsQueryResult = Apollo.QueryResult<AllChatsQuery, AllChatsQueryVariables>;
export const BlockedChatsDocument = gql`
  query blockedChats($numberUuid: ID!, $limit: Int, $offset: Int, $orderBy: String!, $state: String) {
    blockedChats(numberUuid: $numberUuid, orderBy: $orderBy, state: $state, limit: $limit, offset: $offset) {
      ...ChatSearchResultsFragment
    }
  }
  ${ChatSearchResultsFragmentDoc}
`;

/**
 * __useBlockedChatsQuery__
 *
 * To run a query within a React component, call `useBlockedChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockedChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockedChatsQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useBlockedChatsQuery(
  baseOptions: Apollo.QueryHookOptions<BlockedChatsQuery, BlockedChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlockedChatsQuery, BlockedChatsQueryVariables>(BlockedChatsDocument, options);
}
export function useBlockedChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BlockedChatsQuery, BlockedChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlockedChatsQuery, BlockedChatsQueryVariables>(BlockedChatsDocument, options);
}
export type BlockedChatsQueryHookResult = ReturnType<typeof useBlockedChatsQuery>;
export type BlockedChatsLazyQueryHookResult = ReturnType<typeof useBlockedChatsLazyQuery>;
export type BlockedChatsQueryResult = Apollo.QueryResult<BlockedChatsQuery, BlockedChatsQueryVariables>;
export const UnassignedChatsDocument = gql`
  query unassignedChats($numberUuid: ID!, $limit: Int, $offset: Int, $orderBy: String!, $state: String) {
    unassignedChats(numberUuid: $numberUuid, orderBy: $orderBy, state: $state, limit: $limit, offset: $offset) {
      ...ChatSearchResultsFragment
    }
  }
  ${ChatSearchResultsFragmentDoc}
`;

/**
 * __useUnassignedChatsQuery__
 *
 * To run a query within a React component, call `useUnassignedChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnassignedChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnassignedChatsQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUnassignedChatsQuery(
  baseOptions: Apollo.QueryHookOptions<UnassignedChatsQuery, UnassignedChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnassignedChatsQuery, UnassignedChatsQueryVariables>(UnassignedChatsDocument, options);
}
export function useUnassignedChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UnassignedChatsQuery, UnassignedChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnassignedChatsQuery, UnassignedChatsQueryVariables>(UnassignedChatsDocument, options);
}
export type UnassignedChatsQueryHookResult = ReturnType<typeof useUnassignedChatsQuery>;
export type UnassignedChatsLazyQueryHookResult = ReturnType<typeof useUnassignedChatsLazyQuery>;
export type UnassignedChatsQueryResult = Apollo.QueryResult<UnassignedChatsQuery, UnassignedChatsQueryVariables>;
export const WithBotChatsDocument = gql`
  query withBotChats($numberUuid: ID!, $limit: Int, $offset: Int, $orderBy: String!, $state: String) {
    withBot(numberUuid: $numberUuid, orderBy: $orderBy, state: $state, limit: $limit, offset: $offset) {
      ...ChatSearchResultsFragment
    }
  }
  ${ChatSearchResultsFragmentDoc}
`;

/**
 * __useWithBotChatsQuery__
 *
 * To run a query within a React component, call `useWithBotChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWithBotChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWithBotChatsQuery({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useWithBotChatsQuery(
  baseOptions: Apollo.QueryHookOptions<WithBotChatsQuery, WithBotChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WithBotChatsQuery, WithBotChatsQueryVariables>(WithBotChatsDocument, options);
}
export function useWithBotChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WithBotChatsQuery, WithBotChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WithBotChatsQuery, WithBotChatsQueryVariables>(WithBotChatsDocument, options);
}
export type WithBotChatsQueryHookResult = ReturnType<typeof useWithBotChatsQuery>;
export type WithBotChatsLazyQueryHookResult = ReturnType<typeof useWithBotChatsLazyQuery>;
export type WithBotChatsQueryResult = Apollo.QueryResult<WithBotChatsQuery, WithBotChatsQueryVariables>;
