import { useEffect } from "react";

export const usePageTitle = (pageTitle: string | null) => {
  useEffect(() => {
    if (!pageTitle) return;

    updatePageTitle(pageTitle);

    return () => {
      document.title = "Turn";
    };
  }, [pageTitle]);
};

export const updatePageTitle = (pageTitle: string | null) => {
  document.title = pageTitle ? `${pageTitle} - Turn` : "Turn";
};
