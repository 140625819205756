import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "ds/Box";
import { Button } from "ds/Button";
import { ShadowLow } from "ds/Core";
import { FeatureCallout } from "ds/FeatureCallout/src/FeatureCallout";
import { useNavigation } from "hooks/useNavigation";
import { FeatureFlag, isEnabledFeature } from "utils/featureFlags";

const KEY = "ai-for-all-callout-viewed";

export const AIForAllCallout = () => {
  const { t } = useTranslation();
  const { navigateTo } = useNavigation();

  /**
   * We do not want to show this to customers who already have LLM enabled.
   * We also don't want it to appear if the FF is removed at any point
   * in the future, so we write the viewed stated to localStorage automatically.
   */
  const isLLMActive = isEnabledFeature(FeatureFlag.LLM);
  const _active = !isLLMActive && window.localStorage.getItem(KEY) !== "true";
  const [active, setActive] = React.useState(_active);
  useEffect(() => {
    if (isLLMActive && !window.localStorage.getItem(KEY)) {
      window.localStorage.setItem(KEY, "true");
    }
  }, [isLLMActive]);

  const handleDismiss = () => {
    setActive(false);
    window.localStorage.setItem(KEY, "true");
  };

  return (
    <FeatureCallout
      active={active}
      title={t("build.stack.canvas.settings.ai-config.announcement-popup.title")}
      description={t("build.stack.canvas.settings.ai-config.announcement-popup.description")}
      videoLink="https://www.youtube.com/embed/ipzdgpmccWg?si=xMn-0wwGVS_dqMCz"
      onDismiss={handleDismiss}
      learnMoreUrl="https://learn.turn.io/l/en/category/j9wfl3do9j-ai-powered"
      actions={
        <Box flexDirection="column" gap="100">
          <Button
            onClick={navigateTo("settings-ai", { newTab: true })}
            variation="primary"
            size="micro"
            text={t("build.stack.canvas.settings.ai-config.announcement-popup.button")}
            style={{ boxShadow: ShadowLow }}
          />
        </Box>
      }
    />
  );
};
