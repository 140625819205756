import React from "react";

import { useEventOutside } from "hooks/useEventOutside";
import { Override } from "types";

import { StyledContainer, StyledDrop } from "./styles";

type IDropDownProps = Override<
  Omit<JSX.IntrinsicElements["div"], "ref">,
  {
    action: React.ReactElement;
    content?: React.ReactNode;
    visible?: boolean;
    onHide: () => void;
    children?: React.ReactElement;
    drop?: {
      top?: string;
      width?: string;
      minHeight?: string;
    };
  }
>;

export const Dropdown = ({ visible, action, content, children, drop, onHide, ...props }: IDropDownProps) => {
  const [clickOutsideRef] = useEventOutside(onHide, {
    useVisibility: true,
    visible,
    ignoreQuery: ["ignore-onclickoutside"],
  });

  return (
    <StyledContainer ref={clickOutsideRef} {...props}>
      {action}
      <StyledDrop className="ignore-onclickoutside" visible={visible} {...drop}>
        {children && React.cloneElement(children, { visible, onHide })}
      </StyledDrop>
      {content}
    </StyledContainer>
  );
};
