export const LIVEVIEW_REQUEST_REACT_OPEN_NAV = "LIVEVIEW_REQUEST_REACT_OPEN_NAV";
export const LIVEVIEW_REQUEST_REACT_NAVIGATE = "LIVEVIEW_REQUEST_REACT_NAVIGATE";
export const LIVEVIEW_REQUEST_SHOW_INBOX_PROFILE_MODAL = "LIVEVIEW_REQUEST_SHOW_INBOX_PROFILE_MODAL";
export const LIVEVIEW_REQUEST_HIDE_INBOX_PROFILE_MODAL = "LIVEVIEW_REQUEST_HIDE_INBOX_PROFILE_MODAL";
export const LIVEVIEW_REQUEST_SHOW_INBOX_IMAGE_MODAL = "LIVEVIEW_REQUEST_SHOW_INBOX_IMAGE_MODAL";
export const LIVEVIEW_REQUEST_REFRESH_JOURNEY = "LIVEVIEW_REQUEST_REFRESH_JOURNEY";
export const LIVEVIEW_REQUEST_SHOW_OVERLAY = "LIVEVIEW_REQUEST_SHOW_OVERLAY";
export const LIVEVIEW_REQUEST_HIDE_OVERLAY = "LIVEVIEW_REQUEST_HIDE_OVERLAY";
export const LIVEVIEW_REQUEST_SELECT_CHAT = "LIVEVIEW_REQUEST_SELECT_CHAT";
export const LIVEVIEW_SET_INBOX_CALL_QUEUE = "LIVEVIEW_SET_INBOX_CALL_QUEUE";
export const LIVEVIEW_REMOVE_INBOX_CALL = "LIVEVIEW_REMOVE_INBOX_CALL";
export const LIVEVIEW_ADD_INBOX_CALL = "LIVEVIEW_ADD_INBOX_CALL";
export const LIVEVIEW_SET_INBOX_CALL_QUEUE_ID = "LIVEVIEW_SET_INBOX_CALL_QUEUE_ID";
export const REACT_CHANGED_VIEWPORT = "REACT_CHANGED_VIEWPORT";
export const REACT_NAVIGATED = "REACT_NAVIGATED";
export const VIEWPORT_DESKTOP = "VIEWPORT_DESKTOP";
export const VIEWPORT_MOBILE = "VIEWPORT_MOBILE";

export const EVENT_LIVEVIEW_ACTIVE = "EVENT_LIVEVIEW_ACTIVE";
