/* eslint-disable react/jsx-no-undef */
import { Box, BoxExtendedProps, BoxProps, Drop, DropProps, Text, TextExtendedProps } from "grommet";
import React, { PropsWithChildren, ReactElement, ReactNode, useRef, useState } from "react";
import ReactTooltip, { TooltipProps } from "react-tooltip";
import styled from "styled-components";

import {
  ColorBrandDefault,
  ColorGrayBase,
  ColorGrayDark,
  ColorGrayDarkest,
  ColorGrayLight,
  ColorNeutralWhite,
} from "ds/Core";
import { useResponsiveContext } from "hooks";
import { Override } from "types";

import { HelpIcon } from "../icons/HelpIcon";

export const StyledTooltip = styled(Drop)`
  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
`;
export const StyledTooltipContainer = styled.div`
  display: inline-block;
`;

interface ITooltip {
  title: string;
  borderColor: string;
}

type TTooltipProps = ITooltip & DropProps;

export const Tooltip = ({ children, title, align, borderColor = ColorGrayBase }: PropsWithChildren<TTooltipProps>) => {
  const [visible, setVisible] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const onMouseOver = () => setVisible(true);
  const onMouseOut = () => setVisible(false);
  return (
    <StyledTooltipContainer>
      <Box tag="span" width="max-content" ref={boxRef} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        {title}
      </Box>
      {visible && (
        <StyledTooltip align={align} target={boxRef.current as unknown as React.RefObject<HTMLDivElement>}>
          <Box
            align="center"
            round="5px"
            overflow="hidden"
            background={ColorNeutralWhite}
            border={{ color: borderColor, size: "xsmall" }}
            elevation="medium"
            width={{
              min: "150px",
              max: "400px",
            }}
            pad="10px"
          >
            {children}
          </Box>
        </StyledTooltip>
      )}
    </StyledTooltipContainer>
  );
};

Tooltip.defaultProps = {
  align: {
    top: "top",
    left: "right",
  },
};

const StyledTooltipIconContainer = styled(Box)`
  flex-shrink: 0;

  .type-light {
    padding: 18px !important;
    border: none !important;
    max-width: 400px;
    width: max-content;
    box-shadow: 0px 4px 10px rgba(13, 31, 38, 0.08);
    border-radius: 5px;
    opacity: 1 !important;
  }
`;
type StyledButtonProps = {
  active?: boolean;
  margin?: string;
  disabled?: boolean;
};
const StyledButton = styled.span<StyledButtonProps>`
  font-family: "Menlo", "Monaco", "Andale Mono", "Courier New", monospace;
  font-weight: ${({ active }) => (active ? "bold" : "300")};
  color: ${({ active }) => (active ? ColorBrandDefault : ColorGrayDarkest)};
  margin: ${({ margin }) => margin || "0 15px 0 0"};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  &:hover {
    opacity: 0.7;
  }
`;

export const StyledTooltipTitle = styled(Text)`
  font-family: "DM Sans";
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${({ color }) => color || ColorGrayDark};
  margin-bottom: 11px;
  text-align: left;
`;

export const StyledTooltipDescription = styled(Text)`
  font-family: "DM Sans";
  font-size: 12px;
  line-height: 18px;
  color: #7a8285;
  text-align: left;
`;

type TStyledTooltipProps = Override<
  TooltipProps,
  {
    round?: string;
    width?: string;
    opacity?: string;
    padding?: string;
  }
>;

const StyledReactTooltip = styled(ReactTooltip)<TStyledTooltipProps>`
  &.__react_component_tooltip {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
    max-width: ${({ width }) => width || "300px"};
    ${({ round }) => round && `border-radius: ${round};`}
    ${({ padding }) => padding && `padding: ${padding};`}

    &.show {
      ${({ opacity }) => opacity && `opacity: ${opacity};`}
    }
  }
`;

const CodeText = styled(Text)`
  align-self: center;
  color: ${ColorGrayDark};
  font-size: 10px;
  font-weight: 700;
  padding-left: 4px;
  padding-right: 4px;
`;

const DescriptionTitleText = styled(Text)`
  font-size: 10px;
  font-weight: 700;
  text-align: center;
`;

interface ITooltipIcon {
  id: string;
  icon?: ReactNode;
  fill?: string;
  children?: ReactNode | string | ReactElement;
  actionProps?: StyledButtonProps;
  tooltipProps?: TooltipProps;
  trigger?: ReactNode | ReactElement;
  style?: React.CSSProperties;
}
type TTooltipIconProps = ITooltipIcon & BoxProps;

export const TooltipIcon = ({
  id,
  icon,
  fill,
  children,
  actionProps,
  tooltipProps,
  trigger,
  ...props
}: TTooltipIconProps) => {
  const { sizeSmallerThan } = useResponsiveContext();
  const isSmaller = sizeSmallerThan("lmedium");

  return (
    <StyledTooltipIconContainer {...props}>
      {trigger || (
        <StyledButton key={id} data-tip data-for={id} {...actionProps}>
          {icon || <HelpIcon fill={fill} />}
        </StyledButton>
      )}
      <ReactTooltip
        id={id}
        type="light"
        place="right"
        effect="solid"
        clickable={!isSmaller}
        disable={isSmaller}
        event={isSmaller ? "focus" : undefined}
        {...tooltipProps}
      >
        <Box direction="column">{children}</Box>
      </ReactTooltip>
    </StyledTooltipIconContainer>
  );
};

type TooltipComponentProps<ComponentProps> = Override<
  ComponentProps,
  {
    children: ReactNode;
    component: React.ComponentType<ComponentProps>;
    id: string;
    tooltip: ReactNode;
    tooltipProps?: TStyledTooltipProps;
  }
>;

function TooltipComponent<ComponentProps>({
  children,
  component: Component,
  id,
  tooltip,
  tooltipProps,
  ...props
}: TooltipComponentProps<ComponentProps>) {
  return (
    <>
      <Component data-tip data-for={id} {...(props as unknown as ComponentProps)}>
        {children}
      </Component>
      <StyledReactTooltip id={id} type="light" place="right" effect="solid" clickable {...tooltipProps}>
        <Box direction="column">{tooltip}</Box>
      </StyledReactTooltip>
    </>
  );
}

type TooltipBoxProps = Omit<TooltipComponentProps<BoxExtendedProps>, "component">;
export const TooltipBox = (props: TooltipBoxProps) => <TooltipComponent component={Box} {...props} />;

type TooltipTextProps = Omit<TooltipComponentProps<TextExtendedProps>, "component">;
export const TooltipText = (props: TooltipTextProps) => <TooltipComponent component={Text} {...props} />;

interface InlineStyleTooltipDescriptionProps extends BoxExtendedProps {
  topLineText: string;
  bottomRightText?: string;
  bottomLeftText?: string;
}

export const InlineStyleTooltipDescription = ({
  topLineText,
  bottomRightText,
  bottomLeftText = "CTRL OR COMMAND",
  ...props
}: InlineStyleTooltipDescriptionProps) => {
  if (bottomRightText) {
    return (
      <StyledTooltipDescription {...props}>
        <Box direction="column" margin={{ vertical: "-6px" }}>
          <DescriptionTitleText>{topLineText}</DescriptionTitleText>
          <Box direction="row">
            <CodeBlockText text={bottomLeftText} />
            <Text margin={{ left: "5px", right: "5px" }}>+</Text>
            <CodeBlockText text={bottomRightText} />
          </Box>
        </Box>
      </StyledTooltipDescription>
    );
  }
  return (
    <StyledTooltipDescription {...props}>
      <Box direction="column" margin={{ vertical: "-10px" }}>
        <DescriptionTitleText>{topLineText}</DescriptionTitleText>
      </Box>
    </StyledTooltipDescription>
  );
};

type CodeBlockTextProps = {
  text: string;
};

const CodeBlockText = ({ text }: CodeBlockTextProps) => (
  <Box background={ColorGrayLight} direction="row">
    <CodeText>{text}</CodeText>
  </Box>
);
