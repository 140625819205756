import { ReactElement, ReactNode } from "react";
import React from "react";

import { AvatarProps } from "ds/Avatar/src/types";
import { BoxProps } from "ds/Box/src";
import { DropPositions, DropProps } from "ds/Drop/src/types";
import { Override, SpaceType } from "ds/types";

export enum DropdownSize {
  "LARGE" = "large",
  "SMALL" = "small",
}

export enum DropdownVariation {
  "PRIMARY" = "primary",
}

export type DropdownSizes = `${DropdownSize}`;
export type DropdownVariations = `${DropdownVariation}`;

export interface DropdownStyleProps extends React.ComponentPropsWithoutRef<"a"> {
  variation?: DropdownVariation;
  color?: string;
  background?: string;
  disabled?: boolean;
  open?: boolean;
  hasSecondLevel?: boolean;
  size?: `${DropdownSize}`;
  itemProps?: {
    size?: `${DropdownSize}`;
  };
}

export interface DropdownItemBaseProps extends DropdownStyleProps {
  label?: ReactNode;
  icon?: ReactNode;
  avatar?: Omit<AvatarProps, "size">;
  description?: ReactNode;
  selected?: boolean;
  secondLevel?: boolean;
  position?: DropPositions;
  children?: ReactElement<DropdownProps>;
  isLevelDrop?: boolean;
  stopPropagation?: boolean;
  helpText?: string;
}

export type DropdownNestedProps = Omit<DropdownProps, "size">;

export type DropdownItemProps = SpaceType &
  DropdownItemBaseProps & {
    children?: ReactElement<DropdownNestedProps>;
  };

export type DropdownProps = Override<
  SpaceType & DropdownItemBaseProps,
  {
    "data-testid"?: string;
    header?: ReactElement<DropdownNestedProps> | ReactElement<DropdownNestedProps>[] | ReactNode;
    headerProps?: React.HTMLAttributes<HTMLDivElement>;
    contentProps?: React.HTMLAttributes<HTMLDivElement>;
    footer?: ReactElement<DropdownNestedProps> | ReactElement<DropdownNestedProps>[] | ReactNode;
    trigger?: ReactElement<DropdownNestedProps>;
    position?: DropPositions;
    children?: ReactNode;
    content?: ReactElement<DropdownNestedProps>[] | React.ReactFragment | string;
    dropProps?: BoxProps & {
      // context(Puvvl, 2023-11-09) this prop help to change a drop content position when content updating (height of trigger)
      updatePosition?: any;
    };
    onShow?: () => void;
    onHide?: () => void;
    visible?: boolean;
    loading?: boolean;
    loadingText?: string;
    closeOnClick?: boolean;
    disableOutsideClick?: boolean;
    drop?: DropProps;
    active?: boolean;
  }
>;
