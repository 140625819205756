import i18n from "i18next";
import _capitalize from "lodash/capitalize";
import { initReactI18next } from "react-i18next";

import { DEFAULT_LOCALE } from "utils/context/locale/constants";
import { getLocaleCookie } from "utils/context/locale/utils/cookies";
import { isDevOrQA } from "utils/featureFlags";

import common_en from "./translations/en/common.json";
import common_pt from "./translations/pt/common.json";
import common_tlh from "./translations/tlh/common.json";

const resources = {
  en: {
    common: common_en,
  },
  pt: {
    common: common_pt,
  },
  // CrowdIn in-context pseudo-language should only available in Dev/QA environments.
  ...(isDevOrQA
    ? {
        tlh: {
          common: common_tlh,
        },
      }
    : {}),
};

const options = {
  lng: getLocaleCookie() ?? DEFAULT_LOCALE,
  debug: false,
  returnNull: false,
  resources,
  interpolation: {
    escapeValue: false,
    format: (value, format) =>
      value
        ? format === "quotes"
          ? `"${value}"`
          : format === "uppercase"
            ? value.toUpperCase()
            : format === "lowercase"
              ? value.toLowerCase()
              : format === "capitalize"
                ? _capitalize(value)
                : value
        : value,
  },
  defaultNS: "common",
};

i18n.use(initReactI18next).init(options);

export default i18n;
